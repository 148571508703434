import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translationEN from './locales/en.json';
import translationNL from './locales/nl.json';
import translationIT from './locales/it.json';

const resources = {
  en: {
    translation: translationEN
  },
  nl: {
    translation: translationNL
  },
  it: {
    translation: translationIT
  }
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: localStorage.getItem("LNG") || "nl", 
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false
    }
  });

  export default i18n;